import React, { useCallback } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import useContentfulImage from "../hooks/useContentfulImage"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Trans, t } from "@lingui/macro"
import { I18nProvider, I18n } from "@lingui/react"
import catalogEs from "../locales/es/messages"
import catalogBr from "../locales/br/messages"
import catalogFr from "../locales/fr/messages"
import catalogIt from "../locales/it/messages"
import catalogDe from "../locales/de/messages"

const slugify = require("slugify")



export default props => {

  const CustomComponent = ({ node }) => (
    <section>{documentToReactComponents(node, options)}</section>
  )
  
  
  
  const CustomH1 = ({ string, id }) => <h1 id={id} className="mt-4 mb-3">{string}</h1>
  const CustomH2 = ({ string, id, ad }) => {
    if (ad) {
      return (<>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      <ins className="adsbygoogle"
          style={{display:"block", textAlign:"center"}}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-8800979330857975"
          data-ad-slot="6169923305"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
      <h2 id={id} className="py-6">{string}</h2>
      </>)
    } 
    else{
      return (<>
      <h2 id={id} className="py-6">{string}</h2>
      </>)
    } 
  }
  const CustomH3 = ({ string, id }) => (
    <h3 id={id} className="py-2" style={{ "scroll-margin-top": "2.5em" }}>
      {string}
    </h3>
  )
  const CustomImageComponent = ({ title, description, fluid }) => (
    <Img
      className="mt-5 mb-5 mx-auto d-block"
      style={{ maxWidth: 854, maxHeight: 480 }}
      fluid={JSON.parse(fluid)}
      title={title}
      alt={title}
      fadeIn="false"
      loading="eager"
    />
  )
  
  const headers = []
  
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        let myNode = {}
        //If entry is Amazon Book
        if(node.data.target.fields.cover){
          myNode = node.data.target.fields;
          return <CustomBookComponent node={myNode} />
        }
        else {
          //If entry is another kind of entry
          myNode = node.data.target.fields.sectionBody["en-US"]
          const id = slugify(myNode.content[0].content[0].value, {
            lower: true,
            remove: /[*+~.()'"!:@]/g,
          })
        }
        return <CustomComponent node={myNode} />
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const fluid = useContentfulImage(
          JSON.stringify(node.data.target.fields.file["en-US"].url)
        )
        const title = JSON.stringify(node.data.target.fields.description["en-US"])
        const description = JSON.stringify(
          node.data.target.fields.file["en-US"].description
        )
        return (
          <CustomImageComponent
            title={title}
            alt={title}
            description={description}
            fluid={JSON.stringify(fluid)}
          />
        )
      },
      [BLOCKS.HEADING_1]: node => {
        const string = node.content[0].value
        return <CustomH1 string={string} />
      },
      [BLOCKS.HEADING_2]: node => {
        let ad=false;
        // Add ad based on a random number (1-4)
        if(Math.floor(Math.random() * 4) + 1 > 3) {
          ad=true
        }
        const string = node.content[0].value
        const id = slugify(node.content[0].value, {
          lower: true,
          remove: /[*+~.()'"!:@]/g,
        })
        headers.push(string)
        return <CustomH2 string={string} id={id} ad={ad} />
      },
      [BLOCKS.HEADING_3]: node => {
        const string = node.content[0].value
        const id = slugify(node.content[0].value, {
          lower: true,
          remove: /[*+~.()'"!:@]/g,
        })
        return <CustomH3 string={string} id={id} />
      },
      [INLINES.HYPERLINK]: node => {
        if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <div class="item mt-5 mb-5">
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-10 col-lg-8">
                  <div class="content-figure">
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe
                        class="embed-responsive-item"
                        src={node.data.uri}
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        } else if (node.data.uri.startsWith("/")) {
          return (
            <Link to={node.data.uri} title={node.content[0].value}>
              {node.content[0].value}
            </Link>
          )
        } else {
          return (
            <OutboundLink
              href={node.data.uri}
              target="_blank"
              rel="nofollow noopener noreferrer"
              title={node.content[0].value}
            >
              {node.content[0].value}
            </OutboundLink>
          )
        }
      },
    },
  }
  
  const CustomBookComponent = ({ node }) => (
    <section>
      {/* <div class="row" style={{background: "rgb(2, 125, 194)", background: "radial-gradient(circle, rgba(2, 125, 194,1) 0%, rgba(148,187,233,0) 80%)"}}> */}
      <div class="row" style={{background: "rgb("+ node.rgb['en-US'] + ")", background: "radial-gradient(circle, rgba(" + node.rgb['en-US'] + ",1) 0%, rgba(148,187,233,0) 80%)"}}>
          <div class="col-12 d-none d-md-block">
            
          {props.pageContext.lang === "es" && <a target="_blank" rel="noopener noreferrer" href={node.linkEs["en-US"]} style={{textDecoration:"none"}}>
              <p style={{float: "left", paddingLeft:"30%"}}>
                <img
                  src={"https:" + node.cover["en-US"].fields.file["en-US"].url}
                  className="mt-5 mb-5 mx-auto d-block"
                  style={{ maxWidth: 200, maxHeight: 200 }}
                  title={node.cover["en-US"].fields.title["en-US"]}
                  alt={node.cover["en-US"].fields.description["en-US"]}
                />  
              </p>
              <p style={{paddingTop:"130px", color:"black"}}><strong>{node.title["en-US"]}</strong><br/>{node.author["en-US"]}</p>
            </a>}

            {props.pageContext.lang === "br" && <a target="_blank" rel="noopener noreferrer" href={node.linkBr["en-US"]} style={{textDecoration:"none"}}>
              <p style={{float: "left", paddingLeft:"30%"}}>
                <img
                  src={"https:" + node.cover["en-US"].fields.file["en-US"].url}
                  className="mt-5 mb-5 mx-auto d-block"
                  style={{ maxWidth: 200, maxHeight: 200 }}
                  title={node.cover["en-US"].fields.title["en-US"]}
                  alt={node.cover["en-US"].fields.description["en-US"]}
                />  
              </p>
              <p style={{paddingTop:"130px", color:"black"}}><strong>{node.title["en-US"]}</strong><br/>{node.author["en-US"]}</p>
            </a>}

            {props.pageContext.lang === "fr" && <a target="_blank" rel="noopener noreferrer" href={node.linkFr["en-US"]} style={{textDecoration:"none"}}>
              <p style={{float: "left", paddingLeft:"30%"}}>
                <img
                  src={"https:" + node.cover["en-US"].fields.file["en-US"].url}
                  className="mt-5 mb-5 mx-auto d-block"
                  style={{ maxWidth: 200, maxHeight: 200 }}
                  title={node.cover["en-US"].fields.title["en-US"]}
                  alt={node.cover["en-US"].fields.description["en-US"]}
                />  
              </p>
              <p style={{paddingTop:"130px", color:"black"}}><strong>{node.title["en-US"]}</strong><br/>{node.author["en-US"]}</p>
            </a>}

            {props.pageContext.lang === "it" && <a target="_blank" rel="noopener noreferrer" href={node.linkIt["en-US"]} style={{textDecoration:"none"}}>
              <p style={{float: "left", paddingLeft:"30%"}}>
                <img
                  src={"https:" + node.cover["en-US"].fields.file["en-US"].url}
                  className="mt-5 mb-5 mx-auto d-block"
                  style={{ maxWidth: 200, maxHeight: 200 }}
                  title={node.cover["en-US"].fields.title["en-US"]}
                  alt={node.cover["en-US"].fields.description["en-US"]}
                />  
              </p>
              <p style={{paddingTop:"130px", color:"black"}}><strong>{node.title["en-US"]}</strong><br/>{node.author["en-US"]}</p>
            </a>}

            {props.pageContext.lang === "de" && <a target="_blank" rel="noopener noreferrer" href={node.linkDe["en-US"]} style={{textDecoration:"none"}}>
              <p style={{float: "left", paddingLeft:"30%"}}>
                <img
                  src={"https:" + node.cover["en-US"].fields.file["en-US"].url}
                  className="mt-5 mb-5 mx-auto d-block"
                  style={{ maxWidth: 200, maxHeight: 200 }}
                  title={node.cover["en-US"].fields.title["en-US"]}
                  alt={node.cover["en-US"].fields.description["en-US"]}
                />  
              </p>
              <p style={{paddingTop:"130px", color:"black"}}><strong>{node.title["en-US"]}</strong><br/>{node.author["en-US"]}</p>
            </a>}

          </div>


          <div class="col-12 d-md-none">
            <a target="_blank" rel="noopener noreferrer" href={node.linkEs["en-US"]} style={{textDecoration:"none"}}>
              <p style={{float: "left"}}>
                <img
                  src={"https:" + node.cover["en-US"].fields.file["en-US"].url}
                  className="mt-5 mb-5 mx-auto d-block"
                  style={{ maxWidth: 200, maxHeight: 200 }}
                  title={node.cover["en-US"].fields.title["en-US"]}
                  alt={node.cover["en-US"].fields.description["en-US"]}
                />  
              </p>
              <p style={{paddingTop:"130px", color:"black"}}><strong>{node.title["en-US"]}</strong><br/>{node.author["en-US"]}</p>
            </a>
          </div>   
      </div>
    </section>)

  return (
  <>
    <I18nProvider
      language={props.data.contentfulTerm.lang}
      catalogs={{
        //en: catalogEn,
        es: catalogEs,
        it: catalogIt,
        de: catalogDe,
        br: catalogBr,
        fr: catalogFr,
      }}
    >
      <Layout
        image={props.data.contentfulTerm.heroImage.file.url}
        lang={props.data.contentfulTerm.lang}
        title={props.data.contentfulTerm.title}
        description={props.data.contentfulTerm.description.description}
        paths={props.pageContext.termpostPath}
        pageType="term-post"
        datePublished={props.data.contentfulTerm.publishDate}
        dateModified={props.data.contentfulTerm.updatedAt}
        enSlug={props.data.contentfulTerm.enSlug}
        deSlug={props.data.contentfulTerm.deSlug}
        esSlug={props.data.contentfulTerm.esSlug}
        ptSlug={props.data.contentfulTerm.ptSlug}
        frSlug={props.data.contentfulTerm.frSlug}
        itSlug={props.data.contentfulTerm.itSlug}
      >
        {/*<SEO title={props.data.contentfulBlogPost.title} />*/}
        <div className="container mt-5 mb-5">
          <h1>{props.data.contentfulTerm.h1}</h1>

          <p style={{ color: "silver", fontSize: "0.8em" }}>
            <Link to="/" style={{ color: "slategray" }}>
              Home
            </Link>{" "}
            »{props.data.contentfulTerm.lang !== "es" && " "}
            {props.data.contentfulTerm.lang !== "es" && (
              <Link
                to={`/${props.data.contentfulTerm.lang}/`}
                style={{ color: "slategray" }}
              >
                {`${props.data.contentfulTerm.lang}`.toUpperCase()}
              </Link>
            )}
            {props.data.contentfulTerm.lang !== "es" && " »"}{" "}
            {props.data.contentfulTerm.lang === "es" && (
              <I18n>
                {({ i18n }) => (
                  <Link
                    to={i18n._(t`/que-es`)}
                    title={i18n._(t`¿Qué es...?`)}
                    style={{ color: "slategray" }}
                  >
                    <Trans>¿Qué es...?</Trans>
                  </Link>
                )}
              </I18n>
            )}{" "}
            {props.data.contentfulTerm.lang !== "es" && (
              <I18n>
                {({ i18n }) => (
                  <Link
                    to={i18n._(t`/que-es`)}
                    title={i18n._(t`¿Qué es...?`)}
                    style={{ color: "slategray" }}
                  >
                    <Trans>¿Qué es...?</Trans>
                  </Link>
                )}
              </I18n>
            )}{" "}
            » {props.data.contentfulTerm.h1}
          </p>

          {props.data.contentfulTerm.heroImage && (
            <Img
              className="featured mb-3"
              style={{ maxHeight: "18em" }}
              fluid={props.data.contentfulTerm.heroImage.fluid}
              alt={props.data.contentfulTerm.title}
              fadeIn="false"
              loading="eager"
            />
          )}

          <hr />
          <section>
            <div id="faq-accordion" class="faq-accordion mx-auto">
              <div class="card border-0 mb-4 shadow-sm rounded">
                <div class="card-header border-0 p-4 theme-bg-light rounded">
                  <h4 class="card-title mb-0">
                    <a
                      data-parent="#faq-accordion"
                      data-toggle="collapse"
                      class="card-toggle collapsed"
                      href="#toc"
                      aria-expanded="false"
                    >
                      <i class="fas fa-chevron-down"></i>
                      &nbsp;<Trans>Tabla de contenidos</Trans>
                    </a>
                  </h4>
                </div>

                <div class="card-collapse collapse" id="toc">
                  <div class="card-body p-4 bg-white rounded">
                    <ul class="list-unstyled">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: generateTOC(props.data.contentfulTerm),
                        }}
                      ></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {documentToReactComponents(
            props.data.contentfulTerm.richTextBody.json,
            options
          )}
        </div>
      </Layout>
    </I18nProvider>
  </>
)
          }

export const query = graphql`
  query($id: String!) {
    contentfulTerm(id: { eq: $id }) {
      h1
      lang
      title
      description {
        description
      }
      publishDate
      updatedAt
      heroImage {
        file {
          url
        }
        title
        fluid(maxHeight: 600, maxWidth: 1200, quality: 60, cropFocus: CENTER) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      richTextBody {
        json
      }
      enSlug
      deSlug
      esSlug
      ptSlug
      frSlug
      itSlug
    }
  }
`

function generateTOC(post) {
  let h2s = ""
  let h3s = ""
  post.richTextBody.json.content.forEach(element => {
    if (element.nodeType === "heading-2") {
      if (h2s.length !== 0) {
        h2s = h2s + h3s + "</ul>"
      }
      const h2 = JSON.stringify(element.content[0].value)
      h2s =
        h2s +
        '<li><a title="' +
        h2.substring(1, h2.length - 1) +
        "\" href='#" +
        slugify(h2, {
          lower: true,
          remove: /[*+~.()'"!:@]/g,
        }) +
        '\' style="text-decoration:none;font-size:0.8rem">» ' +
        h2.substring(1, h2.length - 1) +
        "</a></li>" +
        '<ul class="list-unstyled">'
      h3s = ""
    } else if (element.nodeType === "heading-3") {
      const h3 = JSON.stringify(element.content[0].value)
      h3s =
        h3s +
        '<li><a title="' +
        h3.substring(1, h3.length - 1) +
        "\" href='#" +
        slugify(h3, {
          lower: true,
          remove: /[*+~.()'"!:@]/g,
        }) +
        '\' style="text-decoration:none;font-size:0.7rem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· ' +
        h3.substring(1, h3.length - 1) +
        "</a></li>"
    }
  })
  return h2s
}
